import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import TadaServiceContext from '../context/tada_service_context'
import { prettyPrice, syncShoppingList, analytics } from '@utils'
import { useAccount } from '@functions/hooks'
import {
   changeModal,
   setCartGoods,
   setLocalShipping,
   setMinTotalOrder,
   setPackages,
   setShippingMethod,
   setShoppingList
} from '@redux/actions'

import { logEvent } from 'firebase/analytics'
import CartDeliveryForm from '../common/forms/cart_delivery_form'
import Loading from '../common/loading'
import CartGoods from './cart_goods'
import Package from './package'
import s from './cart.module.scss'
import ChoseMarketForm from '@components/common/forms/chose_market_form'

const Cart = () => {

   const dispatch = useDispatch()
   const tadaService = useContext(TadaServiceContext)

   const shoppingList = useSelector(state => state.shoppingList)
   const choosenShopId = useSelector(state => state.choosenShopId)
   const cartGoods = useSelector(state => state.cartGoods)
   const packages = useSelector(state => state.packages)
   const minTotalOrder = useSelector(state => state.minTotalOrder)
   const choosenCity = useSelector(state => state.choosenCity)
   const choosenAddress = useSelector(state => state.choosenAddress)
   const shippingMethod = useSelector(state => state.shippingMethod)
   const shippingAddress = useSelector(state => state.shippingAddress)
   const token = useSelector(state => state.token)

   const [loading, setLoading] = useState(!!cartGoods.length)
   const [hideOrderBtn, setHideOrderBtn] = useState(false)
   const [shippings, setShippings] = useState([])
   const [validationState, setValidationState] = useState(
      Object.keys(shoppingList).reduce((acc, key) => {
         acc[key] = false
         return acc
      }, {})
   )

   const shoppingListCodes = Object.keys(shoppingList)

   const [cardGoodsCount, setCardGoodsCount] = useState(shoppingListCodes.length || 0)

   useEffect(() => {
      setHideOrderBtn(Object.values(validationState).some(bool => bool))
   }, [shoppingList, validationState])

   useEffect(() => {
      if (!shoppingListCodes.length || shoppingListCodes.length < cardGoodsCount)
         return setCardGoodsCount(shoppingListCodes.length)

      let cancelled = false

      setCardGoodsCount(shoppingListCodes.length)
      setLoading(true)

      const getConcatedShoppingList = async () => {
         const cartList = await tadaService
            .getCartList(token)
            .catch(tadaService.logError)

         return syncShoppingList(cartList, shoppingList)
      }

      const fetchCart = async () => {
         try {
            const synchronizedShoppingList = token ? await getConcatedShoppingList() : shoppingList
            const newShoppingList = await tadaService.shoppingList(choosenShopId, shoppingList)

            if (token) {
               const goodsWithQuantity = Object.keys(synchronizedShoppingList).map(goodCode => ({
                  goodCode,
                  quantity: synchronizedShoppingList[goodCode]
               }))

               tadaService
                  .addGoodsToCart(goodsWithQuantity, token)
                  .catch(tadaService.logError)
            }

            dispatch(setCartGoods(newShoppingList.goods))
            dispatch(setMinTotalOrder(newShoppingList.minTotalOrder))
            dispatch(setShippingMethod(newShoppingList.shippings[0].method))
            dispatch(setLocalShipping(newShoppingList.shippings[0]))
            dispatch(setShoppingList(synchronizedShoppingList))
            setShippings(newShoppingList.shippings)
         } catch (err) {
            tadaService.logError(err)
         } finally {
            !cancelled && setLoading(false)
         }
      }

      fetchCart()

      return () => cancelled = true
   }, [shoppingListCodes.length, choosenShopId, token])

   useEffect(() => {
      if (packages) return

      let cancelled = false

      if (!cancelled) {
         tadaService
            .fetchBagList(choosenShopId)
            .then(packages => {
               dispatch(setPackages(packages))
            })
      }

      return () => cancelled = true
   }, [])

   if (loading) {
      return <Loading />
   }

   if (!cartGoods.length) {
      return (
         <div className={ s.cart_void }>
            <span>Ваш кошик ще порожній :(</span>
            <p>Перегляньте акції чи товари з надкушеними цінами. <br />
               Ви обов'язково знайдете собі щось до смаку!</p>
         </div>
      )
   }

   const invalidItem = cartGoods.find(({ totalQuantity, goodCode }) => totalQuantity < shoppingList[goodCode])

   return (
      <div className={ s.cart }>
         <div className={ s.inner }>
            <div className={ s.cart_goods }>
               <CartGoods
                  cartGoods={ cartGoods }
                  shoppingList={ shoppingList }
                  setHideOrderBtn={ setHideOrderBtn }
                  setValidationState={ setValidationState }
               />
               <Package />
            </div>
            <div className={ s.cart_offer }>
               <OfferInfo
                  cartGoods={ cartGoods }
                  shoppingList={ shoppingList }
                  minTotalOrder={ minTotalOrder }
                  cartValid={ !invalidItem && shippingMethod && (shippingMethod?.needAddress ? shippingAddress : true) }
                  hasInvalidItem={ !!invalidItem }
                  choosenCity={ choosenCity }
                  choosenAddress={ choosenAddress }
                  hideOrderBtn={ hideOrderBtn }
                  shippings={ shippings }
               />
            </div>
         </div>
      </div>
   )
}

const OfferInfo = ({
   cartGoods,
   shoppingList,
   minTotalOrder,
   cartValid,
   hasInvalidItem,
   hideOrderBtn,
   shippings
}) => {

   const { toAuthorization } = useAccount()
   const dispatch = useDispatch()
   const router = useRouter()
   const token = useSelector(state => state.token)
   const choosenCity = useSelector(state => state.choosenCity)
   const choosenCityNP = useSelector(state => state.choosenCityNP)
   const isNPShown = useSelector(state => state.isNPShown)

   const cost = cartGoods.reduce((sum, { goodCode, price }) => sum + shoppingList[goodCode] * prettyPrice(price), 0)

   const enoughCost = cost >= minTotalOrder

   const [ReactPixel, setReactPixel] = useState(null)
   //facebook PIXEL
   useEffect(() => {
      import('react-facebook-pixel')
         .then((module) => {
            setReactPixel(module.default)
         })
   }, [])


   const offerClick = async () => {
      if (!cartValid || hideOrderBtn) return
      const analyticsInstance = analytics()
      if (!token) {
         return toAuthorization({
            title: 'Ваш номер телефону',
            updateUserShop: true,
            onSuccessLoggedIn: () => {
               dispatch(changeModal({
                  title: 'Ваш кошик!',
                  inner: <Cart />
               }))
            }
         })
      }

      const cityToCheck = (
         (
            choosenCityNP !== null &&
            choosenCityNP !== undefined
         )
         ||
         (
            choosenCity !== null &&
            choosenCity !== undefined
         )
      )

      if (!cityToCheck) {
         dispatch(changeModal({
            title: 'Оберіть спосіб доставки',
            inner: <ChoseMarketForm />,
            required: false
         }))
         return
      }

      ReactPixel.track('InitiateCheckout') //facebook PIXEL
      logEvent(analyticsInstance, 'make_offer', ...cartGoods)//firebaseAnalitics
      dispatch(changeModal(null))
      await router.push('/checkout')
   }

   return (
      <div className={ s.offer_info }>
         <div className={ s.goods_count }>
            <b className={ s.count }>Товарів: {cartGoods.length}</b>
            <span className={ s.goods_price }>{prettyPrice(cost)} грн</span>
         </div>
         <p>*Кількість наявного товару може змінюватись залежно від обраного супермаркету.</p>

         {
            !enoughCost && <MinCost minTotalOrder={ minTotalOrder } />
            //? <MinCost minTotalOrder={ minTotalOrder }/>
            //: <CartDeliveryForm cost={ cost } shippings={ shippings } enoughCost={ enoughCost }/>
         }

         <p className={ s.not_allowed_info }>
            {hasInvalidItem ? 'Деяких товарів немає в обраній кількості на даному супермаркеті' : null}
         </p>

         {
            enoughCost ? (
               <button
                  className={ s.offer_btn }
                  disabled={ !cartValid || hideOrderBtn }
                  onClick={ offerClick }
               >
                  <img src="/images/common/shopping-basket.svg" alt="" />
                  Оформити замовлення
               </button>
            ) : (
               <button className={ s.offer_btn } onClick={ () => dispatch(changeModal(null)) }>
                  Додати ще товари
               </button>
            )
         }
      </div>
   )
}

const MinCost = ({ minTotalOrder }) => (
   <div className={ s.min_cost }>
      <b className={ s.title }>Увага! Мінімальна сума замовлення</b>
      <span className={ s.cost }>{minTotalOrder} грн</span>
   </div>
)

export default Cart
