import Image from 'next/image'

import { useDispatch, useSelector } from 'react-redux'
import { changeModal } from '@redux/actions'

import Cart from '@components/cart'
import ChoseMarketForm from '@components/common/forms/chose_market_form'
import s from './cart_icon.module.scss'

const CartIcon = () => {
   const dispatch = useDispatch()

   const { shoppingList, choosenShopId } = useSelector(({ shoppingList, choosenShopId }) => ({ shoppingList, choosenShopId }))

   const total = Object.keys(shoppingList).reduce((sum, goodCode) => sum + shoppingList[goodCode], 0)

   const handleClick = () => {
      if(choosenShopId ) {//&& choosenShopId !== 8
         dispatch(changeModal({
            title: 'Ваш кошик!',
            inner: <Cart />
         }))
      } else {
         dispatch(changeModal({ title: 'Оберіть спосіб доставки', inner: <ChoseMarketForm /> }))
      }
   }

   return (
      <div
         className={ s.cart_icon }
         onClick={ handleClick }
      >
         { total > 0 && <div className={ s.goods_count }>{total}</div> }
         <Image
            src={ '/images/common/shopping-basket.svg' }
            width={ 21 }
            height={ 20 }
            onError={ e => console.log(e) }
            alt=""
         />
      </div>
   )
}
export default CartIcon
