import { useSelector, useDispatch } from 'react-redux'
import { useContext } from 'react'

import TadaServiceContext from '../../components/context/tada_service_context'
import {
   setCategories,
   setChoosenAddress,
   setChoosenCity,
   setChoosenCityNP,
   setChoosenAddressNP,
   generalSetter,
   setPopularList,
   setShopId,
   setSpecialsList,
   setProfileInfo,
   setNewGoodsList
} from '@redux/actions'
import { setCookie } from '@utils'

export default function useSetMarket() {

   const dispatch = useDispatch()
   const tadaService = useContext(TadaServiceContext)
   const { token, citiesNPList, profileInfo } = useSelector(state => ({ token: state.token, citiesNPList: state.citiesNPList, profileInfo: state.profileInfo }))

   const saveMarketLocal = (city, address, isNP, cityNP, warehouse) => {
      setCookie(null, 'shopId', address.shopId, { maxAge: 31 * 24 * 60 * 60 }) // 31 days
      if (isNP) {
         setCookie(null, 'cityNP', JSON.stringify(cityNP), { maxAge: 31 * 24 * 60 * 60 }) // 31 days
         setCookie(null, 'warehouse', JSON.stringify(warehouse), { maxAge: 31 * 24 * 60 * 60 }) // 31 days
         setCookie(null, 'isNPShown', JSON.stringify(true), { maxAge: 31 * 24 * 60 * 60 }) // 31 days

         const choosenCurrCity = profileInfo?.delivery ? profileInfo.delivery.city : cityNP.label
         const choosenCurrAddress = profileInfo?.delivery ? profileInfo.delivery.address : warehouse.label

         dispatch(generalSetter({
            choosenCityNP: choosenCurrCity,// set info to store
            choosenAddressNP: choosenCurrAddress,
            isNPShown: true,
            choosenShopId: address.shopId
         }))
      } else {
         const tempCity = { ...city }
         delete tempCity.items
         setCookie(null, 'city', JSON.stringify(tempCity), { maxAge: 31 * 24 * 60 * 60 }) // 31 days
         setCookie(null, 'address', JSON.stringify(address), { maxAge: 31 * 24 * 60 * 60 }) // 31 days
         setCookie(null, 'isNPShown', JSON.stringify(false), { maxAge: 31 * 24 * 60 * 60 }) // 31 days

         dispatch(generalSetter({
            choosenCity: city?.label,
            choosenAddress: address?.label,
            isNPShown: false,
            choosenShopId: address?.shopId
         }))
      }

      Promise.allSettled([
         tadaService
            .specialsList(address.shopId)
            .then(res => dispatch(setSpecialsList(res)))
            .catch(tadaService.logError),
         tadaService
            .popularList({ shopId: address.shopId })
            .then(res => dispatch(setPopularList(res.resultGoods)))
            .catch(tadaService.logError),
         tadaService
            .newGoodsList({
               categoryId: null,
               shopId: address.shopId,
               filterInStock: true,
               filterPrice: null,
               sort: { direction: 'ASC', limit: 10, offset: 0, order: 'none' },
               filterAttributes: null,
               options: { previewSize: '177x177' }
            })
            .then(res => dispatch(setNewGoodsList(res.resultGoods)))
            .catch(tadaService.logError),
         tadaService
            .structuresCategoriesList(address.shopId)
            .then(res => dispatch(setCategories(res)))
            .catch(tadaService.logError),
      ])

   }

   const setMarket = async (city, address, userToken = token, cityNP, warehouse, isNP) => {
      const needSend = !!userToken || isNP
      const choosenNPcity = isNP && await tadaService.getNPCitiesList().then(res => {
         return res.find(({ label, id }) => typeof cityNP === 'string' ? label === cityNP : id === cityNP.id)
      })
      const choosenWarehouse = isNP && await tadaService.getNPWarehouseList(choosenNPcity.id).then(wareRes => {
         return wareRes.find(({ label, id }) => typeof warehouse === 'string' ? label === warehouse : id === warehouse.id)
      })
      const res = needSend && await tadaService
         .selectedShopSave(userToken, address?.shopId, choosenWarehouse?.id, isNP)
         .catch(e => {
            tadaService.logError(e)
            return e
         })

      if (res?.error) {
         return res
      }

      if (res)
         dispatch(setProfileInfo({ delivery: res.delivery }))

      const args = needSend
         ? [
            city,
            { ...res.shop, value: res.shop.address, label: res.shop.address, shopId: res.shop.shop_id },
            isNP,
            choosenNPcity,
            choosenWarehouse
         ]
         : [
            city,
            address,
            isNP,
            choosenNPcity,
            choosenWarehouse
         ]
      saveMarketLocal(...args)
   }

   return { setMarket, saveMarketLocal }
}
